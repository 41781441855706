<template>
  <div class="vizualizer" ref="vizualizer">
    <canvas ref="canvas" width="393" height="150"></canvas>
    <audio ref="audio" id="audio" />
  </div>
</template>

<script>
import { createNoise3D } from "simplex-noise";
import gsap from "gsap";

const LINES = 1;
const LINE_COLOR = "#1a4e7c";
const PRECISION = 10;
const FREQUENCY = 0.002;
const params = {
  amplitude: 10,
};

const simplex = createNoise3D();

class Line {
  constructor(context, i = Math.random()) {
    this.context = context;
    this.canvas = context.canvas;
    this.i = i;
  }
  get stepX() {
    return this.canvas.width / PRECISION;
  }
  update(t) {
    // prevent lines crossing at the edges
    const y = (this.i - LINES * 0.5 + 0.5) * 5;
    const offset = this.canvas.height / 2 + y;
    this.points = new Array(Math.ceil(this.stepX) + 1).fill(0).map((_, i) => {
      // increase amplitude in middle
      const multiplier =
        Math.sin(
          ((this.canvas.width - i * PRECISION) / this.canvas.width) * Math.PI
        ) * 0.75;
      return {
        x: i * PRECISION,
        y:
          simplex(i * PRECISION * FREQUENCY, this.i, t) *
            params.amplitude *
            multiplier +
          offset,
      };
    });
  }
}

class AudioWave {
  constructor(context, color) {
    this.context = context;
    this.canvas = context.canvas;
    this.lines = [];
    this.color = color;
    for (let i = 0; i < LINES; i++) {
      this.lines.push(new Line(context, i));
    }
  }
  get stepX() {
    return this.canvas.width / PRECISION;
  }
  draw(t) {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.lines.forEach((line) => line.update(t));
    this.context.globalAlpha = 0.33;

    // const shape1 = [...this.lines[0].points, ...this.lines[2].points.reverse()];
    const shape1 = [...this.lines[0].points];
    this.context.lineWidth = 3;
    this.context.save();
    this.context.beginPath();
    shape1.forEach(({ x, y }) => {
      this.context.lineTo(x, y);
    });
    let gradient = this.context.createLinearGradient(
      0,
      0,
      this.canvas.width,
      0
    );

    this.context.closePath();
    this.context.restore();
    this.context.globalAlpha = 0.5;

    this.context.restore();
    this.context.globalAlpha = 1;
    this.lines.forEach(({ points }) => {
      this.context.save();
      this.context.beginPath();
      points.forEach(({ x, y }, i) => {
        this.context.lineTo(x, y);
      });
      this.context.strokeStyle = this.color;
      this.context.stroke();
      this.context.closePath();
      this.context.restore();
    });
  }
}

export default {
  name: "AudioVizualizer",
  props: {
    play: {
      type: Boolean,
      default: false,
    },
    volume: {
      type: Number,
      required: true,
    },
    audio: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  watch: {
    volume(value) {
      if (this.$refs.audio) {
        this.$refs.audio.volume = value / 100;
      }
    },
    audio(value) {
      gsap.to(params, {
        duration: 1.5,
        amplitude: 10,
        ease: "expo.out",
      });
      this.$emit("status", "load");
      this.$refs.audio.pause();
      this.$refs.audio.src = value;
      if (this.play) {
        this.$refs.audio
          .play()
          .then(() => {
            this.$emit("status", "play");
            gsap.to(params, {
              duration: 1.5,
              amplitude: 100,
              ease: "expo.out",
            });
          })
          .catch(() => {
            this.$emit("status", "pause");
            gsap.to(params, {
              duration: 1.5,
              amplitude: 10,
              ease: "expo.out",
            });
          });
      } else {
        this.$emit("status", "pause");
        this.$refs.audio.pause();
        gsap.to(params, {
          duration: 1.5,
          amplitude: 10,
          ease: "expo.out",
        });
      }
    },
    play(isPlay) {
      console.log("w", isPlay);
      if (isPlay) {
        this.$emit("status", "load");
        this.$refs.audio.src = this.audio;
        this.$refs.audio
          .play()
          .then(() => {
            this.$emit("status", "play");
            gsap.to(params, {
              duration: 1.5,
              amplitude: 100,
              ease: "expo.out",
            });
          })
          .catch(() => {
            this.$emit("status", "pause");
            gsap.to(params, {
              duration: 1.5,
              amplitude: 10,
              ease: "expo.out",
            });
          });
      } else {
        this.$emit("status", "pause");
        this.$refs.audio.pause();
        gsap.to(params, {
          duration: 1.5,
          amplitude: 10,
          ease: "expo.out",
        });
      }
      // gsap.to(params, {
      //   duration: 1.5,
      //   amplitude: isPlay ? 100 : 10,
      //   ease: "expo.out",
      // });
    },
  },
  mounted() {
    this.$refs.audio.volume = this.volume / 100;
    const root = this.$refs.vizualizer;
    const canvas = this.$refs.canvas;

    const context = canvas.getContext("2d");
    const audioWave = new AudioWave(context, this.color);

    const resizeObserver = new ResizeObserver(([entry]) => {
      const { width, height } = entry.contentRect;
      canvas.width = width;
      canvas.height = height;
    });

    resizeObserver.observe(root);
    function raf(t) {
      audioWave.draw(t * 0.001);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
  },
};
</script>

<style lang="scss" scoped>
.vizualizer {
  width: 100%;
  height: 100%;
}
</style>
