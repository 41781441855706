<template>
  <div :class="{ 'block--loading': loading }" :style="styleSkeletonWrapper">
    <slot v-if="!loading"></slot>
    <div v-else class="skeleton" :style="styleSkeleton">
    </div>
  </div>
</template>

<script>
export default {
  name: "Skeleton",
  props: {
    loading: {
      type: Boolean,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "",
    },
    styles: {
      type: String,
      default: "",
    },
  },
  computed: {
    styleSkeleton() {
      return `min-width: ${this.width}; height: ${this.height}; ${this.styles}`;
    },
    styleSkeletonWrapper() {
      return `min-width: ${this.width}; height: ${this.height}; ${this.styles}`;
    },
  }
};
</script>

<style lang="scss" scoped>
$primary-color: #ffffff52;
$secondary-color: rgba(255, 255, 255, 0.5);

.block--loading {
  display: flex;
  min-height: 1.1em;
  color: transparent;
  background: none !important;
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
  .skeleton {
    height: 0.8em;
    line-height: 0;
    margin: auto 0;
    background: linear-gradient(
          -75deg,
          transparent 40%,
          $primary-color,
          transparent 60%
        )
        0 0 / 200% 100%,
      $secondary-color;
    animation: skeleton 1s infinite linear;
    background-attachment: fixed;
    border-radius: 10px;
  }
}

@keyframes skeleton {
  to {
    background-position: 200% 0;
  }
}
</style>
