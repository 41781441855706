<template>
  <div
    class="base-select"
    :class="[
      state ? `base-select--${state}` : '',
      loading ? 'base-select--not-click' : '',
    ]"
    tabIndex="0"
    ref="select"
    @blur="blur"
    @click="switchfocus"
  >
    <div class="base-select__label">
      {{ label }}
      <span v-if="required" class="base-select__label--required"> *</span>
    </div>
    <input
      class="base-select__value"
      :class="filter ? 'base-select__value--filter' : ''"
      type="text"
      :readonly="!filter"
      v-model="input"
      ref="input"
      @input="change"
      @blur="blurInput"
    />
    <i
      class="base-select__icon"
      :class="
        loading
          ? 'bx bx-loader-alt base-select--loading'
          : 'bx bxs-chevron-down'
      "
    ></i>
    <div class="base-select__options" ref="options" @scroll="scroll">
      <div
        v-if="
          isFirstItem &&
          typeof firstItem === 'object' &&
          Object.keys(firstItem).length
        "
        class="base-select__option"
        :class="
          firstItem[field] === value[field] ? 'base-select__option--active' : ''
        "
        @click="select(firstItem)"
      >
        {{ firstItem[firstTitle] }}
      </div>
      <div
        v-for="(option, index) in options"
        :key="'option-' + option[field] + '-' + index"
        class="base-select__option"
        :class="
          option[field] === value[field] ? 'base-select__option--active' : ''
        "
        @click="select(option)"
      >
        {{ convertTitle(title, option) }}
      </div>
      <div v-if="optionsLoading" class="base-select__option--loading">
        Загрузка...
      </div>
      <div v-if="optionsNotData" class="base-select__option--not-data">
        Нет данных
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StreamsSelect",
  props: {
    state: {
      type: String,
      default: "default",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "label",
    },
    field: {
      type: String,
      default: "field",
    },
    filter: {
      type: Boolean,
      default: false,
    },
    method: {
      type: String,
      default: "",
    },
    search: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isFirstItem: {
      type: Boolean,
      default: false,
    },
    firstTitle: {
      type: String,
    },
    firstItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      parameters: {
        page: 1,
        concat: false,
      },
      optionsLoading: false,
      optionsNotData: false,
      isNext: true,
      timerId: 0,
      input: "",
      valueSearch: "",
      isFocus: false,
    };
  },
  created() {
    this.input = this.convertTitle(this.title, this.value);
  },
  watch: {
    value() {
      this.input = this.convertTitle(this.title, this.value);
    },
    valueSearch() {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.$store.dispatch(this.search, this.valueSearch).then((response) => {
          if (response.length) {
            this.optionsNotData = false;
          } else {
            this.optionsNotData = true;
          }
        });
      }, 600);
    },
  },
  methods: {
    switchfocus() {
      if (this.isFocus) {
        this.isFocus = false;
        this.$refs.input.blur();
        this.$refs.select.blur();
      } else {
        this.isFocus = true;
      }
    },
    convertTitle(title, option) {
      let t_option = option;
      let t_title = title;

      if (
        this.isFirstItem &&
        t_option[this.field] === this.firstItem[this.field]
      ) {
        t_option = this.firstItem;
        t_title = this.firstTitle;
      }
      if (/{*}/.test(t_title)) {
        return (
          title.replace(/{(.*?)}/gm, function (value) {
            return t_option[value.replace(/{|}/g, "")];
          }) || ""
        );
      } else {
        return t_option[t_title];
      }
    },
    blurInput() {
      this.input = this.convertTitle(this.title, this.value);
      this.valueSearch = "";
    },
    blur() {
      if (!this.filter) {
        return null;
      }
      this.parameters.page = 1;
      this.parameters.concat = false;
      const options = this.$refs.options;
      options.scrollTop = 0;
      this.$store.dispatch(this.method, this.parameters).finally(() => {
        this.isNext = true;
      });
    },
    change() {
      this.valueSearch = this.input;
    },
    scroll() {
      if (!this.filter) {
        return null;
      }
      const options = this.$refs.options;
      if (this.isNext && options.scrollTop + 200 >= options.scrollHeight) {
        this.optionsLoading = true;
        this.parameters.page += 1;
        this.parameters.concat = true;
        this.isNext = false;
        this.$store
          .dispatch(this.method, this.parameters)
          .then((response) => {
            if (response.length) {
              this.isNext = true;
            } else {
              this.isNext = false;
            }
          })
          .finally(() => {
            this.optionsLoading = false;
          });
      }
    },
    select(option) {
      this.$emit("input", option);
      this.$refs.select.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #fff;
$tertiary-color: #33ad85;
$background-color: #009966;

.base-select {
  font-size: 13px;
  position: relative;
  border-radius: 12px;
  min-height: 38px;
  max-height: 38px;
  height: 38px;
  transition: all 0.3s ease;
  color: $primary-color;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &:focus {
    background: $tertiary-color;
    .base-select__value {
      color: $primary-color;
    }
    .base-select__options {
      z-index: 1001;
      opacity: 1;
      margin-top: 0;
      max-height: 200px;
    }
    .base-select__icon {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  &__icon {
    position: absolute;
    z-index: 1000;
    top: 50%;
    right: 10px;
    color: $primary-color;
    transition: all 0.3s ease;
    transform: translateY(-50%);
  }
  &__label {
    position: absolute;
    top: -17px;
    left: 10px;
    &--required {
      color: red;
    }
  }
  &__value {
    color: $primary-color;
    padding: 0 30px 0 15px;
    display: flex;
    height: 100%;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    width: calc(100% - 45px);
    border-radius: 12px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: $tertiary-color;
    }
    &--filter {
      cursor: text;
    }
    &:focus {
      background: $tertiary-color;
      & ~ .base-select__options {
        z-index: 1001;
        opacity: 1;
        margin-top: 0;
        max-height: 200px;
      }
      & ~ .base-select__icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  &--not-click {
    pointer-events: none;
  }
  &--loading {
    animation: 1s loader infinite linear;
  }
  &__options {
    opacity: 0;
    max-height: 0;
    overflow: auto;
    position: absolute;
    top: 42px;
    background: $tertiary-color;
    margin-top: -20px;
    z-index: 1000;
    width: 100%;
    border-radius: 10px;
    transition: all 0.4s ease;
    text-align: left;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &__option {
    padding: 6px 10px;
    margin: 5px;
    font-size: 16px;
    border-radius: 6px;
    transition: margin 0.3s ease;
    &:hover {
      cursor: pointer;
      margin-left: 10px;
    }
    &--active {
      background: $background-color;
      color: $primary-color;
      pointer-events: none;
    }
    &--loading {
      display: flex;
      justify-content: center;
      padding: 6px 10px;
    }
    &--not-data {
      display: flex;
      justify-content: center;
      padding: 6px 10px;
    }
  }
}
@keyframes loader {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}
</style>
