<template>
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.65592 8.22972C7.28398 8.22972 6.93753 8.33786 6.64539 8.52379L3.69704 6.46537C3.74437 6.30014 3.77025 6.12585 3.77025 5.94545C3.77025 5.76501 3.74437 5.59072 3.69704 5.42552L6.5658 3.42265C6.8736 3.64128 7.24957 3.77028 7.65589 3.77028C8.69701 3.77028 9.54102 2.92627 9.54102 1.88515C9.54102 0.844038 8.69703 0 7.65592 0C6.6148 0 5.77079 0.844012 5.77079 1.88513C5.77079 2.02262 5.78592 2.15654 5.81386 2.28566L2.87226 4.3394C2.58512 4.16255 2.24712 4.06032 1.88513 4.06032C0.844012 4.06032 0 4.90433 0 5.94545C0 6.98656 0.843986 7.83057 1.88513 7.83057C2.2471 7.83057 2.58512 7.72834 2.87226 7.55149L5.83657 9.62108C5.79395 9.77852 5.77079 9.94396 5.77079 10.1149C5.77079 11.156 6.6148 12 7.65592 12C8.69703 12 9.54105 11.156 9.54105 10.1149C9.54105 9.07376 8.69703 8.22972 7.65592 8.22972Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "ShareIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
  },
};
</script>
