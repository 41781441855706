<template>
  <div class="wrapper">
    <div class="radio-player" v-if="skeletonLoading || radioStation">
      <div class="station-share" @click="share">
        <Skeleton
          width="100px"
          :loading="skeletonLoading"
          class="station-share__label"
        >
          {{ shareText }}
          <ShareIcon
            class="station-share__label-icon"
            color="rgba(255, 255, 255, 0.5)"
          />
        </Skeleton>
      </div>
      <div>
        <Skeleton :loading="skeletonLoading" class="station-share__name">
          <StationSelect
            v-if="radioStation"
            v-model="radioStation"
            title="station_name"
            field="station_id"
            :options="radioStations"
          />
          <div v-else style="margin: 0 15px">
            {{ radioStation?.station_name || "" }}
          </div>
        </Skeleton>
      </div>
      <Skeleton
        width="135px"
        :loading="skeletonLoading"
        class="recent-tracks__label"
        >Недавно в эфире</Skeleton
      >
      <div class="recent-tracks">
        <table v-if="false" style="border-collapse: collapse">
          <tr class="recent-track">
            <td class="recent-track__name">
              Alan Walker - Sorry (Albert Vishi Remix)
            </td>
            <td class="recent-track__time">13:41</td>
          </tr>
        </table>
      </div>
      <div class="current-track">
        <div class="current-track__sound-visualization">
          <AudioVizualizer
            color="rgba(255, 255, 255, 0.5)"
            :audio="currentAudio"
            :play="isPlay"
            :volume="volume"
            @status="statusPlayer"
          />
        </div>
        <div class="current-track__name" ref="track_name">
          <Skeleton
            :loading="skeletonLoading"
            class="marquee"
            @mouseover.native="marqueeStart"
            @mouseout.native="marqueeStop"
          >
            {{ getTitle }}
          </Skeleton>
        </div>
        <Skeleton
          width="81px"
          :loading="skeletonLoading"
          class="current-track__subname"
          >Сейчас играет</Skeleton
        >
        <div class="current-track__controls">
          <div class="streams">
            <StreamsSelect
              v-if="stream"
              v-model="stream"
              title="bitrate"
              field="id"
              :options="radioStation?.streams || []"
            />
          </div>
          <div style="width: 50%">
            <Skeleton
              width="58px"
              height="58px"
              styles="border-radius: 29px;"
              :loading="skeletonLoading"
              class="button-play"
              :class="`button-play--${status}`"
              @click.native="play"
            ></Skeleton>
          </div>
          <div class="w-100p">
            <Volume v-if="stream" v-model="volume" />
          </div>
        </div>
      </div>
    </div>
    <div class="radio-player" v-else>
      <div style="margin: auto">Нет данных</div>
    </div>
  </div>
</template>

<script>
import Skeleton from "@/components/Skeleton";
import AudioVizualizer from "@/components/AudioVizualizer";
import StreamsSelect from "@/components/StreamsSelect";
import StationSelect from "@/components/StationSelect";
import ShareIcon from "@/components/icons/ShareIcon";
import Volume from "@/components/Volume";

export default {
  name: "player",
  components: {
    Skeleton,
    AudioVizualizer,
    ShareIcon,
    StreamsSelect,
    StationSelect,
    Volume,
  },
  data() {
    return {
      currentAudio: "",
      shareText: "Поделиться",
      isPlay: false,
      status: "default",
      stream: null,
      volume: 50,
      scrollPos: 0,
      timerId: 0,
      skeletonLoading: true,
      radioStation: null,
    };
  },
  created() {
    this.$store.dispatch("player").then(() => {
      if (this.$store.getters.statistics?.length) {
        document.title = this.$store.getters.statistics[0].station_name;
        if (this.$store.getters.statistics[0].streams?.length) {
          this.radioStation = this.$store.getters.statistics[0];
          this.stream = this.radioStation.streams[0];
          this.currentAudio =
            process.env.VUE_APP_AUDIO_HOST + "/" + this.stream.mount;
        }
      }
      this.skeletonLoading = false;
    });
  },
  watch: {
    radioStation(value) {
      if (value?.streams?.length) {
        this.stream = value.streams[0];
      }
    },
    stream(value) {
      console.log("this.isPlay >>> ", this.isPlay);
      if (
        this.currentAudio !==
        process.env.VUE_APP_AUDIO_HOST + "/" + value.mount
      ) {
        this.currentAudio = process.env.VUE_APP_AUDIO_HOST + "/" + value.mount;
      }
    },
  },
  computed: {
    radioStations() {
      if (this.$store.getters.statistics?.length) {
        return this.$store.getters.statistics;
      } else {
        return null;
      }
    },
    getTitle() {
      if (this.$store.getters.currentSong) {
        return this.$store.getters.currentSong;
      } else if (
        this.stream?.last_song?.title &&
        this.stream.last_song.title !== "No title"
      ) {
        return this.stream.last_song.title;
      } else {
        return "Неизвестно";
      }
    },
  },
  methods: {
    copyBuff(value) {
      if (value) {
        const el = document.createElement("textarea");
        el.value = value;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      }
    },
    share() {
      try {
        navigator.share({
          title: "Радио Экспресс 107.8 FM",
          text: "Радио Экспресс 107.8 FM",
          url: window.location.href,
        });
      } catch (e) {
        this.copyBuff(document.location.href);
        this.shareText = "Ссылка скопирована";
        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
          this.shareText = "Поделиться";
        }, 3000);
      }
    },
    marqueeStart(e) {
      e.target.classList.toggle("marquee--start");
      if (e.target.clientWidth < e.target.scrollWidth) {
        e.target.style.marginLeft =
          e.target.clientWidth - e.target.scrollWidth + "px";
      }
    },
    marqueeStop(e) {
      e.target.classList.toggle("marquee--start");
      e.target.style.marginLeft = 0;
    },
    statusPlayer(status) {
      console.log(">> st", status);
      this.$store.commit("stream_id", this.stream.id);
      switch (status) {
        case "play":
          this.status = "pause";
          this.$store.commit("player_status", "playing");
          break;
        case "pause":
          this.status = "default";
          this.$store.commit("player_status", "pausing");
          break;
        case "load":
          this.status = "load";
          this.$store.commit("player_status", "pausing");
          break;
      }
    },
    play() {
      this.isPlay = !this.isPlay;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #fff;
$secondary-color: rgba(255, 255, 255, 0.5);
$tertiary-color: #33ad85;
$background-color: #009966;

.wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #1bae8a;
  overflow: hidden;
  display: flex;
}

.radio-player {
  max-width: 393px;
  width: 100%;
  height: 632px;
  background: $background-color;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0px 0px 40px -27px #000000;
  * {
    box-sizing: border-box;
    color: $primary-color;
  }
}

.station-share {
  cursor: pointer;
  &:active {
    transform: scale(0.98);
  }
  &__label {
    font-size: 11px;
    color: $secondary-color;
    text-transform: uppercase;
    font-weight: 600;
    padding: 25px 25px 0;
    min-width: 9em;
    &-icon {
      margin-left: 10px;
      height: 9px;
    }
  }
  &__name {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0px 10px 0 10px;
    white-space: nowrap;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    min-width: 10em;
  }
}

.recent-track {
  font-size: 14px;
  font-weight: 600;
  &__name {
    padding-bottom: 10px;
    padding-right: 10px;
    color: $secondary-color;
    text-transform: uppercase;
  }
  &__time {
    padding-bottom: 7px;
    color: $secondary-color;
    text-transform: uppercase;
  }
  &s {
    margin-top: 10px;
    overflow: auto;
    padding: 0 25px;
    height: 100%;
    table {
      td {
        text-align: left;
        vertical-align: top;
      }
    }
    & {
      scrollbar-width: thin;
      scrollbar-color: $secondary-color $background-color;
    }
    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-track {
      background: $background-color;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $secondary-color;
      border-radius: 20px;
      border: 3px solid $background-color;
    }
    &__label {
      font-size: 16px;
      min-width: 200px;
      padding: 30px 25px 0;
      font-weight: 600;
    }
  }
}

.current-track {
  display: flex;
  flex-direction: column;
  min-height: 302px;
  &__author {
    font-weight: bold;
    font-size: 15px;
  }
  &__name {
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 25px;
    font-size: 20px;
    overflow: hidden;
    position: relative;
    & .marquee {
      min-width: 100%;
      white-space: nowrap;
      transition: all 0.8s linear;
      &--start {
        transition: all 5s linear !important;
      }
    }
  }
  &__subname {
    min-width: 100px;
    font-weight: bold;
    text-transform: uppercase;
    color: $secondary-color;
    padding: 0 25px;
    font-size: 10px;
  }
  &__sound-visualization {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 156px;
  }
  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 0 0;
    .streams {
      text-align: left;
      padding-left: 10px;
      width: 100%;
    }
  }
}
.w-100p {
  width: 100%;
}

.button-play {
  display: block;
  position: relative;
  border: none;
  outline: none;
  margin: 0 auto;
  background: $primary-color;
  width: 58px;
  height: 58px;
  border-radius: 29px;
  transition: all 0.3s ease;
  outline: none;

  min-width: 58px;
  min-height: 58px;
  font-size: 58px;
  &:before {
    content: "";
    position: absolute;
    background-color: $background-color;
    width: 22px;
    height: 22px;
    top: 50%;
    left: 50%;
    transform: translate(-8px, -11px);
    transition: clip-path 0.3s ease;
    box-sizing: border-box;
  }
  &:after {
    content: "";
    position: absolute;
    background-color: $background-color;
    width: 22px;
    height: 22px;
    top: 50%;
    left: 50%;
    transform: translate(-8px, -11px);
    transition: clip-path 0.3s ease;
    box-sizing: border-box;
  }
  &:before {
    clip-path: polygon(0 0, 23px 11px, 0 23px, 0 0);
  }
  &:after {
    clip-path: polygon(0 0, 23px 11px, 0 23px, 0 0);
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
  &--default {
    &:before {
      display: none;
    }
  }
  &--load {
    &:before {
      animation: loading-player 1s infinite linear;
      clip-path: path(
        "M28 17C28 23.0751 23.0751 28 17 28C10.9249 28 6 23.0751 6 17H0C0 26.3888 7.61116 34 17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C12.3056 0 8.05558 1.90279 4.97918 4.97918L9.22183 9.22183C11.2124 7.23122 13.9624 6 17 6C23.0751 6 28 10.9249 28 17Z"
      );
      height: 34px;
      width: 34px;
      top: 12px;
      left: 12px;
      /* transform: translate(-50%, -50%); */
    }
    &:after {
      display: none;
      clip-path: path(
        "M28 17C28 23.0751 23.0751 28 17 28C10.9249 28 6 23.0751 6 17H0C0 26.3888 7.61116 34 17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C12.3056 0 8.05558 1.90279 4.97918 4.97918L9.22183 9.22183C11.2124 7.23122 13.9624 6 17 6C23.0751 6 28 10.9249 28 17Z"
      );
      height: 34px;
      width: 34px;
      top: 12px;
      left: 12px;
      /* transform: translate(-50%, -50%); */
    }
  }
  &--pause {
    &:before {
      clip-path: polygon(0% 0%, 33% 0%, 33% 100%, 0% 100%);
      transform: translate(-50%, -50%);
    }
    &:after {
      clip-path: polygon(66% 0%, 100% 0%, 100% 100%, 66% 100%);
      transform: translate(-50%, -50%);
    }
  }
}

@media (max-width: 450px), (max-height: 650px) {
  .body {
    background: $background-color !important;
  }
  .radio-player {
    border-radius: 0px;
    max-width: 100%;
    height: 100%;
  }
}
@media (max-width: 820px) and (max-height: 450px) {
  .body {
    background: $background-color !important;
  }
  .radio-player {
    border-radius: 0px;
    max-width: 100%;
    height: 100%;
  }
  .current-track {
    min-height: 250px !important;
  }
  .current-track__sound-visualization {
    height: 107px !important;
  }
  .recent-tracks__label {
    display: none;
  }
  .recent-track {
    display: none;
  }
}

@keyframes loading-player {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes skeleton {
  to {
    background-position: 200% 0;
  }
}
</style>
